import { DataNode } from 'antd/lib/tree'
import useClientConfig from 'config/clients/useClientConfig'
import { get, isArray, isEmpty, reduce } from 'lodash'

/**
 * Parse a full name to initials
 * @param firstName String
 * @param lastName String
 */
export const getAvatarLetters = (
  firstName?: string,
  lastName?: string
): string => {
  return (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '')
}

/**
 * Fix the keys of an entity to match with the tree picker form component
 * @param data any[]
 * @returns any[]
 */
export const treeNormalizer = (data: any[]): any[] => {
  return data.map((entity) => {
    if (isEmpty(entity)) {
      return []
    }

    return {
      ...entity,
      value: entity.id,
      title: entity.description,
      //label: entity.name,
      label: entity.description,
      key: entity.id,
      children: treeNormalizer(entity.children),
    }
  })
}

/**
 * Creates a tree base on a list with parent child relationship
 * @param dataset Any[]
 */
export const createDataTree = (dataset: any[]): any[] => {
  const hashTable = Object.create(null)
  dataset.forEach((aData: any) => {
    hashTable[aData.id] = { ...aData, children: [] }
  })
  const dataTree: any[] = []

  dataset.forEach((aData: any) => {
    if (aData.parentId !== null) {
      hashTable[aData.parentId].children.push(hashTable[aData.id])
    } else {
      dataTree.push(hashTable[aData.id])
    }
  })

  return dataTree
}

/**
 * Parse a tree estructure to a flat list.
 * @param data NodeList
 * @return any[]
 */
export const flatList = (data: BaseEntity[]): BaseEntity[] => {
  const result = {}

  function parseTree(list: BaseEntity[], parent?: string) {
    if (list) {
      list.forEach((entity) => {
        result[entity.id] = { ...entity }
        result[entity.id].childrenCount = entity.children.length
        result[entity.id].childrenAlias = entity.children
        result[entity.id].parent = parent || '-'
        delete result[entity.id].children

        if (entity.children && entity.children.length > 0) {
          parseTree(entity.children, entity.name)
        }
      })
    }
  }

  parseTree(data)

  return Object.values(result)
}

export const findNode = (data: TreeData, key: number): TreeData | null => {
  function searchTree(element: TreeData, id: number): TreeData | null {
    if (element.id === id) {
      return element
    }

    if (element.children !== null) {
      let i = 0
      let result = null

      for (i; result === null && i < element.children.length; i += 1) {
        result = searchTree(element.children[i], id)
      }

      return result
    }

    return null
  }

  return searchTree(data, key)
}

/**
 * Data picker for response.
 */
export const extractValues = (
  data: Record<string, any> | Array<Record<string, any>>,
  keyExtract: string[]
): object => {
  let dataValues = isArray(data) ? [] : {}
  if (keyExtract.length === 0) {
    dataValues = data
  }

  if (keyExtract.length > 0) {
    if (isArray(data)) {
      dataValues = data.map((d) => {
        return reduce(
          keyExtract,
          (result, key, index) => {
            result[key] = get(d, keyExtract[index])
            return result
          },
          {}
        )
      })
    } else {
      dataValues = reduce(
        keyExtract,
        (result, key, index) => {
          result[key] = get(data, keyExtract[index])
          return result
        },
        {}
      )
    }
  }
  // else if (keyExtract.length === 1) {
  //   if (isArray(data)) {
  //     dataValues = data.map((d) => get(d, keyExtract[0]))
  //   } else {
  //     dataValues = get(data, keyExtract[0])
  //   }
  // }

  return dataValues
}

export const getReportExtension = (report: Report): string => {
  const { getMsExcelReportExt } = useClientConfig()
  switch (report.reportType) {
    case 'application/vnd.ms-excel':
      return getMsExcelReportExt()
    case 'application/pdf':
      return 'pdf'
    // TODO: Add the other types like csv and xlsx
    default:
      return 'data'
  }
}

export const processCondition = (
  value: number | string,
  condition: string,
  comparator: number | string
): boolean => {
  switch (condition) {
    case '<':
      return value < comparator
    case '>':
      return value > comparator
    case '=':
      return value === comparator
    case '<=':
      return value <= comparator
    case '>=':
      return value >= comparator
    default:
      return true
  }
}

export const cleanDataTree = (data: any[], labelName= 'name'): DataNode[] => {
  const result: DataNode[] = []
  data.forEach((entity) => {
    if (entity.children.length > 0) {
      result.push({
        title: entity[labelName],
        key: entity.id,
        children: cleanDataTree(entity.children, labelName),
      })
    } else {
      result.push({
        title: entity[labelName],
        key: entity.id,
      })
    }
  })

  return result
}
