import storage from 'redux-persist/lib/storage'
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable'

import { ROLE_CODES_LIST, ROLES } from '../specifics/abanca'
import useClientConfig from './clients/useClientConfig'

const { getVocBackendUrl, getLcmBackendUrl } = useClientConfig()

const base = {
  VOC_API_URL: getVocBackendUrl(),
  LCM_API_URL: getLcmBackendUrl(),
  MENTION_LYTICS_API_URL: 'https://app.mentionlytics.com/api/',
  persistConfig: {
    key: 'root',
    active: true,
    version: 1,
    storage,
    blacklist: ['router'], // reducer keys that you do NOT want stored to persistence here
    whitelist: ['auth', 'account'], // Optionally, just specify the keys you DO want stored to
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator({})],
    timeout: 1,
  },

  i18n: {
    debug: false,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  },

  sso: [
    {
      domain: 'oxxogas-dev.limetropy.com',
      loginURL: 'http://oxxogas-dev.limetropy.com/auth',
      cookieName: 'SSO_AUTH',
    },

    {
      domain: 'jac.limetropy.com',
      loginURL: 'https://jac.limetropy.com/voc/login',
      cookieName: 'SSO_AUTH',
    },
  ],

  specialFeats: {
    951: {
      // Abanca Tenant ID
      domain: 'oxxogas-dev.limetropy.com',
      logicalLevel: true,
      rolesLevel: true,
      rolesMap: ROLE_CODES_LIST.map((role) => ({
        label: ROLES[role].name,
        value: ROLES[role].code,
      })),
    },
  },
}

export default base
