import { DEFAULT_COLORS } from "@components/widgets/apacheEchart/Commons"


export const widgetsBars: CommonWidget = {


  /**************************************************************************
   *                                BARS
  ***************************************************************************/

  barByGeo: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-geo',
      type: 'bar',
      showHistoric: false,
      showValues: true,
      showLegend: true,
      fillMode: 'solid',
      percentageScale: true,
      itemsWidth: 200,
      nps: false,
      colors: DEFAULT_COLORS,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['count', 'group', 'groupName', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
        },
      ],
    },
  },
  barByQuestion: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-question',
      type: 'bar',
      percentageScale: false,
      showValues: true,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'count'],
          label: 'group',
          grouped: 'question',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  barByLogical: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-logical',
      type: 'bar',
      percentageScale: false,
      showValues: true,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'count'],
          label: 'group',
          grouped: 'logicalLocation',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  barByChannel: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-channel',
      type: 'bar',
      percentageScale: false,
      showValues: true,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'count'],
          label: 'group',
          grouped: 'channel',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  
  alertFirstResponseBarByWeek: {
    title: 'Tiempo de respuesta alertas semanal. Base 36 hs.',
    helpLink: '--',
    position: [0, 32],
    size: [4, 2],
    config: {
      id: 'alert-first-response-bar-by-week',
      type: 'bar',
      showHistoric: false,
      showValues: true,
      showLegend: true,
      groupKeys: ['slowPerc', 'fastPerc'],
      fillMode: 'solid',
      percentageScale: true,
      nps: false,
      itemsWidth: 200,
      colors: DEFAULT_COLORS,
      groupLabels: {
        fastPerc: 'Respuesta menor a 36 hrs',
        slowPerc: 'Respuesta mayor a 36 hrs',
      },
      indicators: [
        {
          indicator: 'alert-first-response-time',
          grouped: 'formattedWeek',
          keyExtract: ['count', 'fastPerc', 'slowPerc', 'group'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED'
          },
        },
      ],
    },
  },

  alertFirstResponseBarByMonth: {
    title: 'Tiempo de respuesta alertas mensual. Base 36 hs.',
    helpLink: '--',
    position: [0, 34],
    size: [4, 2],
    config: {
      id: 'alert-first-response-bar-by-month',
      type: 'bar',
      showHistoric: false,
      showValues: true,
      showLegend: true,
      groupKeys: ['slowPerc', 'fastPerc'],
      fillMode: 'solid',
      percentageScale: true,
      itemsWidth: 200,
      nps: false,
      colors: DEFAULT_COLORS,
      groupLabels: {
        fastPerc: 'Respuesta menor a 36 hrs',
        slowPerc: 'Respuesta mayor a 36 hrs',
      },
      indicators: [
        {
          indicator: 'alert-first-response-time',
          grouped: 'formattedMonth',
          keyExtract: ['count', 'fastPerc', 'slowPerc', 'group', 'groupName'],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED'
          },
        },
      ],
    },
  },
  alertFirstResponseBarByGeo: {
    title: 'Tiempo de respuesta alertas por geo. Base 36 hs.',
    helpLink: '--',
    position: [0, 36],
    size: [4, 2],
    config: {
      id: 'alert-first-response-bar-by-geo',
      type: 'bar',
      showHistoric: false,
      showValues: true,
      showLegend: true,
      groupKeys: ['slowPerc', 'fastPerc'],
      fillMode: 'solid',
      percentageScale: true,
      itemsWidth: 200,
      nps: false,
      colors: DEFAULT_COLORS,
      groupLabels: {
        fastPerc: 'Respuesta menor a 36 hrs',
        slowPerc: 'Respuesta mayor a 36 hrs',
      },
      mapper: (data: any, extra: any) => {
        return data
      },
      indicators: [
        {
          indicator: 'alert-first-response-time',
          grouped: 'geoLocation',
          keyExtract: ['count', 'fastPerc', 'slowPerc', 'group', 'groupName'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED',
          },
        },
      ],
    },
  },

  barByMonthRegression: {
    title: 'FALTA',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-month-regression',
      type: 'bar',
      showHistoric: false,
      showValues: true,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'formattedMonth',
          keyExtract: [
            'group',
            'value',
            'groupName',
            'source',
            'nextX',
            'nextY',
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            periods: '3',
          },
        },
      ],
    },
  },
  stackedBar: {
    title: 'FALTA',
    helpLink: '--',
    position: [0, 18],
    size: [4, 1],
    config: {
      id: 'FALTA',
      type: 'stackedBar',
      colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
      fillMode: '',
      showValues: true,
      showHistoric: false,
      showLegend: true,
      valueFormat: ' >-.0%',
      itemWidth: 220,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'groupId', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            formula: 'count-number'
          }
        }
      ]
    }
  },
}
